/* http://www.css3-generator.de/rgba.html */
body {
  background: linear-gradient(to bottom, rgba(180,220,220,1) 20%, rgba(255,201,131,1) 50%, rgba(198,75,0,1));
  background-attachment:fixed;
  font-size:16px;
  /*font-family: "Times New Roman", Georgia, Serif;*/
  font-family: Arial, Helvetica, sans-serif;
  /*background-color:@gray-lighter;*/
  /*
  background-image:url(../images/background-guitar.gif);
  background-size:cover;
  background-attachment:fixed;
  background-repeat: no-repeat;
  back*/
}


/* header / navbar */
.navbar-default {
    border-color: @gray-middle-lighter;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}
@media (min-width: 979px) {
  ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
  }
}
#navbar {
  background-color:rgba(180,180,180,0.15);
  margin-top:0px;
  margin-bottom:0px;
  /*padding-top: 80px;

  @media (min-width: @screen-xs-max) {
    padding-top: 80px;
  }
  @media (min-width: @screen-md-min) {
    padding-top: 80px;
  }*/
  #navbar-collapse {
    padding-left: 0px;
    padding-right: 0px;
    /* bei Zeilenumbruch Platz für das Suchfeld */
    /*@media (max-width: @screen-md-max) {*/
	margin-top:40px;
    /*}*/
  }
  .navbar-nav > li > a {
    padding: 15px 7px;
  }
  .navbar-nav > li.first > a {
    padding-left:0px;
  }
  .navbar-nav > li.last > a {
    padding-right:0px;
  }
}
.top_header {
  /*background-color:#00ff00;*/
  height:100px;
  margin-top:10px;
}
header {
  margin-top:0px;
}
.page-header {
    display:none;
}
#page-main_title {
  position:relative;
  top:55px;
  padding-left:15px;
  padding-right:20px;
  text-align:right;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;

  color:#ffffff;
  text-shadow: 5px 5px 10px #000000;
  font-size:30px;  
}
.node-teaser {
    h2 {
	font-size:1.5em;
	float:left;
	margin-top:0px;

	a {
	    color:#000000;
	}
    }
    
    li.node-readmore {
	padding-top:3px;
	padding-left:8px;
    }
}



.region-sidebar-first {
  background-color: transparent;
  border: 0;

  &.well {
    box-shadow: none;
    padding-top:0px;
  }
}

/* sidebar navigation */
aside {
  .well {
    margin: 0px;
    padding:0px;
    border-right: 1px solid @gray-lighter;
  }

  .panel-heading,
  .panel-body {
    padding-left: 0px;
  }

  /* hide category for Geschichten etc. */
  .panel-heading.dummy {
    display: none;
  }
}

#block-customcode-sidebar-navigation-block #accordion {
  h4 {
    color:#000000;

    span.badge{
      display:none;
      background-color: @gray-light;
    }

    a:hover.disabled {
      text-decoration: none;
      cursor: default;
    }
  }

  .panel-heading {
    background-color: transparent;
    border: 0;
    padding-top:4px;
    padding-bottom:6px;
  }

  .panel {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .panel-body {
    padding-top: 0px;
    padding-bottom: 0px;
    border:0;
  }

  .list-group {
    margin-bottom:0px;
  }

  .list-group-item {
    padding:4px 0px 4px 12px;
    /*background: linear-gradient(to bottom, rgba(198,75,0,1), rgba(255,201,131,1)  );*/
    background: rgba(80, 80, 80, 0.15);
    color:#000000;
    border: solid 1px rgba(255,201,131,1);
  }

  .list-group-item.active {
    /*background: linear-gradient(to bottom, rgba(255,140,140,1), rgba(255,60,60,1)  );*/
    color: #ffffff;
    /*border: solid 1px rgba(255,60,60,1);*/
    border: solid 1px #FFFFFF;
  }

  .list-group-item:hover {
    border: solid 1px #FFFFFF;
    text-decoration:underline;
  }

  .list-group-item:hover.active {
    border: solid 1px #FFFFFF;
    text-decoration:none;
    cursor: default;
  }
}

/* home / node-type-imagerahmen */
.node-type-imagerahmen .maincontent {
  .col-bilderrahmen {
    @media (min-width: @screen-lg-min) {
	padding-left: 80px;
    }
    @media (max-width: @screen-md-max) {
	padding-left: 40px;
    }
    @media (max-width: @screen-sm-max) {
        padding-left: 25px;
    }
  }

  .col-text {
      @media (min-width: @screen-xs-max) {
        border-right: 1px solid @gray-middle-lighter;
      }
     @media (max-width: @screen-xs-max) {
       margin-bottom: 30px;
     }
  }
  
  .bilder_oben .field-name-field-bilder-oben {
    .field-item {
      .make-xs-column(3);
    }
  }

  .text {
    .make-xs-column(9);
    padding: 0px 50px 0px 50px;
  }

  .bilder_rechts .field-name-field-bilder-rechts {
    .make-xs-column(3);
    .field-item {
      padding-top: 30px;
    }
  }
}

.inner-maincontainer {
  background-color:rgba(180,180,180,0.15);
  padding:15px 15px 50px 15px;
}

.node-type-page .inner-maincontainer {
    padding-right: 30px;
    padding-left:30px;
}

/* hide article lists */
.page-taxonomy-term {
    article {
        display:none;
    }
    ul.pagination {
	display: none;
    }    
}

/* article */
article {
    h2 {
	font-size:24px;
    }
    
    /* Geschichten */
    &.node-geschichten .field-name-body {
	background-color: rgba(215,225,237, 1);
	padding:1px 15px 15px 15px;
	border:1px solid #000000;
    }
    &.node-geschichten .field-name-field-denkanstoesse {
        margin-top:50px;
        background-color: rgba(215,225,237, 1);
	padding:1px 15px 15px 15px;
	border:1px solid #000000;
    }
    
    /* Anspiele */
    &.node-anspiele .field-name-body,
    &.node-besinnung .field-name-body {
        background-color: rgba(0, 0, 0, 0.05);
        padding:1px 15px 15px 15px;
        border:1px solid #000000;
    }
    &.node-anspiele .field-name-field-denkanstoesse,
    &.node-besinnung .field-name-field-denkanstoesse {
        margin-top:50px;
        background-color: rgba(0, 0, 0, 0.05);
        padding:1px 15px 15px 15px;
        border:1px solid #000000;
    }
}

/* teasertext */
.node-imagerahmen h2.block-title {
    font-size:26px;
    margin-left: 15px !important;
    margin-bottom:20px;
    text-decoration:underline;
}
.view-id-teasertext .view-content {
    .views-field-body {
	img {
	    display:none;
	}
	p {
	    margin-left: 15px !important;
	}
    }

    .views-field-nothing .kategorie {
	margin-left: 15px;
	a {
	    color: #000000;
	}
    }
}


/* comments Gästebuch */
.not-logged-in fieldset .panel-body {
    display:none;
}
.page-comment-reply {
    header h2,
    .comment {
	display:none;
    }
}
.node-type-gaestebuch #comments {
    h2.title,
    .comment {
	display: none;
    }
}
.node-gaestebuch footer ul li {
    font-size: 24px;
}
#block-views-comments-recent-gaestebuch {
    .comment-box {
	border: 1px dotted #000000;
	padding:15px 15px 0px 15px;
	margin-bottom:30px;
    }
    .views-row {
	margin-bottom:30px;
	//background-color: rgba(255,255,255,0.75);
	/*border: 1px dotted #000000;*/
	/*padding:0px 15px 15px 15px;*/
	.views-field-comment-body {
    	    font-size:20px;
	}
	.views-field-timestamp {
	    font-size:24px;
	    margin-bottom:6px;
	}
    }
}

.node-type-gaestebuch .pagination {
    display: none;
}

/* search */
header#navbar {
    #search-block-form {
	margin-top: 3px;
    }    
    #block-search-form {
/*	z-index:-1;*/
    }
    .region.region-navigation {
	position:absolute;
	top:0px;
	@media (max-width:  @screen-xs-max) {
	    left:0px;
	}
	@media (min-width:  @screen-sm) {
	    right:15px;
	}
    }
}

@media (max-width:  @screen-xs-max) {
    /* settings for the navbar small displays */
    #navbar #navbar-collapse {
	margin-top: 0px;
    }
    #navbar .navbar-nav > li > a {
	line-height: 3px;
	margin-left: 25px;
    }
    #navbar .navbar-nav > li.first > a {
	padding: 15px 8px;
    }
    
    /* clearing article left margins for small displays */
    article p {
	margin-left: 0px !important;
    }
    
    /* setting small top line to seperate from sub-menu for small displays */
    article {
	border-top: 1px solid #000000;
	padding-top: 20px;
    }
}


/* Galeriezeile 1 Bild */
article.node-galeriezeile-ein-bild {
    margin: 80px 0px 25px;
    padding: 0px 15px;
    @media (max-width:  @screen-xs-max) {
	padding-top: 30px;
    }
    header {
	display: none;
    }
    .field-type-image {
        float: left;
    }
    img {
	display: inline;
        max-width: 100%;
        padding-right: 25px;
        padding-bottom: 15px;
    }
}


/* Galeriezeile 3 Bilder mit Bild/Text */
article.node-galeriezeile-3-bilder-bild-text,
article.node-galeriezeile-2-bilder-bild-text {
    margin: 80px 0px 25px;
    
    header {
	display: none;
    }
    .field-name-body {
	padding: 15px;
    }
    .field-type-field-collection {
	float: left;
	width: 33.33%;
	padding: 15px;
	display: flex;
	justify-content: center;
	
	.field-name-field-buchinfos {
	    padding-top: 15px;	    
	    .field-item {
		/*display: flex;
	        justify-content: space-around;*/
	    }
	}
	.field-type-image .field-items {
	    text-align: left;

	    img {
		display: inline;
		max-width: 100%;
	    }
	}
    }
    > .field-name-field-buchinfos {
	clear: both;
	padding: 0px 15px;
    }
}
article.node-galeriezeile-2-bilder-bild-text .field-type-field-collection {
    width: 50%;
}

/* force display block for captcha for gaestebuch and contact form */
#bootstrap-panel-body {
    display: block;
}

#block-menu-menu-kontaktmenue ul.menu.nav li {
    float: left;
}
#block-menu-menu-kontaktmenue ul.menu.nav li a {
    padding: 10px 8px;
    font-size: 13px;
}
#block-menu-menu-kontaktmenue {
    display: none;
}
@media (min-width: 768px) {
    #block-menu-menu-kontaktmenue {
        display: block;
	float: left !important;
        margin-top: 5px;
    }
    .show_only_s {
	display: none !important;
    }
    .menu.nav.navbar-nav {
        width: 100%;
        display: flex;
        margin-top: 5px;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (max-width: 990px) {
	    justify-content: flex-start;
	}
    }
    .menu.nav.navbar-nav a {
        white-space: nowrap;
    }
    #block-search-form {
	float: left !important;
    }
}
#block-search-form form.search-form {
    width: 200px;
}

